
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');
/* @import 'bootstrap/dist/css/bootstrap.min.css'; */


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* .ui.sidebar.sidebar-menu.menu {
  left: 72px;
  visibility: visible;
  transition: 0.5s;
} */

.ui.sidebar.sidebar-menu.visible.menu {
  left: 0px;
}

.ui.card {
  position: static !important;
}


.action_advance {
  float: right
}

.btn {
  padding: 0.8rem 1rem 0.7rem;
  border: 0.2rem solid #4d4d4d;
  cursor: pointer;
  text-transform: capitalize;
}

.btn__inline {
  padding: 0.8rem 1rem 0.7rem;
  border: 0.2rem solid #4d4d4d;
  cursor: pointer;
  text-transform: capitalize;
}


.btn__primary {
  color: #fff;
  background-color: #1873b9;
  border: 0;
  font-size: 1.4rem;
  padding: 0.8rem;
  margin-bottom: 0rem;
  margin-top: 1.5rem;
  border-radius: 5px;
}


.btn-group {
  display: flex;
  justify-content: space-between;
}
.btn-group > * {
  flex: 1 1 49%;
}
.btn-group > * + * {
  margin-left: 0.8rem;
}
.error {
  font-style: italic;
  margin-right: 10px;
  color: #7B2929;
}

/*.label__lg {
  line-height: 1.01567;
  font-weight: 200;
  padding: 0.4rem;
  margin-bottom: 0rem;
  margin-top: 1.5rem;
  text-align: center;
}
.input__lg {
  padding: 2rem;
  border: 2px solid #000;
  outline: none;
}
.input__lg:focus {
  border-color: #000;
  box-shadow: inset 0 0 0 2px;
  box-shadow: 0 0 5px rgba(180, 180, 200, 1);
}*/
textarea.input__cd {
  border:0;
  resize:none;
  width:calc(100% - 6px);
  background:transparent;
  border:1px solid transparent;
}
.ui.card:hover textarea.input__cd {
  border:.5px dashed #ccc;
}
textarea.input__cd:focus {
  border:1px solid #8F8F8F !important;
  outline:none;
}
header textarea.input__cd {
  border:1px solid transparent;
  font-size:22px;
  line-height:32px;
  padding:0;
  font-weight:400;
  font-family: 'Montserrat';
  display:block;
}
header textarea.input__cd:hover {
  border:1px dashed #8F8F8F;
}
textarea.input__cd:hover {
  border:1px dashed #8F8F8F;
}


input[type="file"] {
    display: none;
}
.image-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    cursor: pointer;
}
.mirco-file-upload {
    cursor: pointer;
}
.image_wrapper_label {
   width: 100%;
   float: right;
}


/*.input__cd {
  border: 0px solid #000;
  resize: none;
  outline: none;
  background: transparent;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #8F8F8F;
  margin-top: 1px;
}
.input__cd:focus {
  border: 1px solid #ccc;
  box-shadow: 0 0 5px rgba(150, 150, 200, 1);
}

.description_cd {
  width: 100%;
  display: inline-block;
  font-size: 0.9rem;
  border: 1px dashed #eee;
}*/

.suggestion {
  text-align: center;
  font-size: 0.9rem;
    padding: 0.6rem;

}

/*[class*="__lg"] {
  display: inline-block;
  width: 100%;
  font-size: 1.9rem;
}*/

.ui.card {
  position: static !important;
}

.take-menu li:hover {
  background-color:#272727;
  color:#fff;
}
.take-menu li:hover svg {
  color:#fff;
}


